/* Template */

.logo {
  display: block;
  margin: 0 auto;
  width: 150px;

  img {
    width: 100%;
  }
}

.grid {
  background: $card-bg;
  border-radius: $border-radius-lg;
  box-shadow: $card-box-shadow;
  margin-bottom: $grid-gutter-width;
  border: $border-width $border-style $card-border-color;
}

.grid-header {
  padding: 15px 20px;
  margin-bottom: 15px;
  font-weight: 500;
  @include ms-respond(font-size, -1);
  letter-spacing: $letter-spacing;
  background: rgba($template-body-color, 1);
  border-left: 3px $border-style theme-color(primary);
  border-radius: 0px $border-radius-lg 0px 0px;
}

.grid-title {
  opacity: 0.9;
  margin-bottom: 25px;
}

.close {
  color: $body-text-color;
}

.split-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action-btn {

    i {
      font-size: 20px;
      color: $text-muted;
      transition: 0.3s ease color;
    }

    &:hover {
      i {
        color: theme-color(primary);
      }
    }
  }

  .content-wrapper {
    display: flex;

    &.v-centered {
      align-items: center;
    }

    &.h-centered {
      justify-content: center;
    }
  }
}

.grid-body {
  padding: 20px 25px 20px;
}

.card-title {
  @include ms-respond(font-size, -1);
  font-weight: 500;
  color: $card-title-color;
  margin-bottom: 0;
}

.equel-grid {
  display: flex;
  align-items: stretch;

  .grid {
    width: 100%;
    min-width: 100%;
  }
}

.showcase_row_area {
  margin-bottom: 20px;

  .showcase_text_area {
    text-align: right;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  .showcase_content_area {
    margin-bottom: -10px;

    .btn {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.content-preview {
  width: 45px;
  height: 45px;

  &.rounded {
    border-radius: 100%;
  }
}